import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, merge, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/services';
import { StorageService } from 'src/app/shared/services';
import { LogicalOperators } from 'src/app/shared/utilities';
import { environment } from 'src/environments/environment';
import { CodesVariableNamesEnum } from '../../../shared/enums';

@Injectable({
  providedIn: 'root'
})
export class ExtensionService {
  private readonly extensionHidePeriod = 1000 * 60 * 60 * 24;

  private extensionId: string = environment.extensionId;
  private updateInfoShown$ = new BehaviorSubject(false);
  private lastInfoClosureDate: Date | null = null;
  private infoShown$: Observable<boolean> = this.updateInfoShown$.asObservable();

  constructor(
    @Inject('Window') private window: any,
    private storage: StorageService,
    private config: ConfigService
  ) {
    // For test env only:
    if (!this.config.isProduction) {
      const storageRecord: string = localStorage.getItem(CodesVariableNamesEnum.testExtensionId);

      if (storageRecord) {
        this.extensionId = storageRecord;
      } else {
        localStorage.setItem(CodesVariableNamesEnum.testExtensionId, '');
      }
    }
  }

  showInstallInfo(): Observable<boolean> {
    if (this.isInfoHidden()) {
      return of(false);
    }

    const triggerExtensionRunningTest$ = this.isExtensionRunning().pipe(
      map(LogicalOperators.negation),
      tap((notRunning) => this.updateInfoShown$.next(notRunning)),
      filter(() => false)
    );

    return merge(this.infoShown$, triggerExtensionRunningTest$);
  }

  hideInfoTemporarily(): void {
    const curDate = new Date();
    this.storage.setExtensionAdCloseDate(curDate);
    this.lastInfoClosureDate = curDate;
    this.updateInfoShown$.next(false);
  }

  isExtensionRunning(): Observable<boolean> {
    if (!this.window?.chrome?.runtime?.sendMessage) {
      return of(false);
    }

    try {
      return from<Promise<any>>(
        this.window.chrome.runtime.sendMessage(this.extensionId, { type: 'check_extension' })
      ).pipe(
        map(() => true),
        catchError(() => of(false))
      );
    } catch (_) {
      return of(false);
    }
  }

  private isInfoHidden(): boolean {
    if (this.lastInfoClosureDate === null) {
      this.lastInfoClosureDate = this.storage.getExtensionAdCloseDate();
    }

    return Date.now() - this.lastInfoClosureDate.getTime() < this.extensionHidePeriod;
  }
}
